<template>
  <div class="app-container">
    <a-spin :spinning="loading">
      <!-- <div class="menu-nav">
      <div>
        <a-icon type="setting" class="menu-nav-icon"></a-icon>
        <span class="menu-nav-text">图像识别</span>
      </div>
      <a-button-group>
        <a-button style="" type="primary" @click="allClear" v-hasPermi="['detect_all_clear']">全部清除</a-button>
        <a-button style="margin-left:10px;" type="primary" v-hasPermi="['detect_recognize']" :disabled="list.length === 0" @click="recognition">识别</a-button>
      </a-button-group>
    </div> -->
      <div class="table-search">
        <a-form-model
          :model="queryParams"
          ref="queryForm"
          size="small"
          layout="inline"
          v-show="showSearch"
          label-width="68px"
        >
          <!--            <a-form-model-item label="开始时间" prop="beginTime">
                <a-date-picker
                v-model="queryParams.beginTime"
                show-time
                valueFormat="YYYY-MM-DD HH:mm:ss"
                allowClear
                />
          </a-form-model-item>
          <a-form-model-item label="结束时间" prop="endTime">
                <a-date-picker
                v-model="queryParams.endTime"
                show-time
                valueFormat="YYYY-MM-DD HH:mm:ss"
                allowClear
                />
          </a-form-model-item>-->
          <a-form-model-item label="样品编号" prop="sampleNumberArr">
            <a-select
              v-model="queryParams.sampleNumberArr"
              mode="multiple"
              style="width: 200px"
              placeholder="请选择搜索内容"
              allowClear
              show-search
            >
              <a-select-option
                v-for="item in sampleNumberList"
                :key="item.sampleNumber"
                :value="item.sampleNumber"
                :title="item.sampleNumber"
                >{{ item.sampleNumber }}</a-select-option
              >
            </a-select>
          </a-form-model-item>

          <!--        <a-form-model-item label="选择图像" prop="folderNameArr">
          <a-select v-model="queryParams.folderNameArr" mode="multiple" style="width:300px;" placeholder="请选择搜索内容" allowClear show-search>
              <a-select-option v-for="item in folderList" :key="item.folderName" :value="item.folderName">{{ item.folderName }}</a-select-option>
          </a-select>
        </a-form-model-item>-->
          <a-form-model-item label="选择模型" prop="modelName">
            <a-select
              v-model="queryParams.modelName"
              style="width: 200px"
              placeholder="请选择AI模型"
              allowClear
              show-search
              @change="changeModelName"
            >
              <a-select-option
                v-for="item in modelList"
                :key="item.name"
                :value="item.name"
                :title="item.name"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="模型版本" prop="modelVersion">
            <a-select
              v-model="queryParams.modelVersion"
              style="width: 200px"
              placeholder="请选择模型版本"
              allowClear
              show-search
            >
              <a-select-option
                v-for="item in modelVersionList"
                :key="item.modelVersion"
                :value="item.modelVersion"
                :title="item.modelVersion"
                >{{ item.modelVersion }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-button
              icon="search"
              type="primary"
              v-hasPermi="['detect_search']"
              @click="handleQuery"
              >查询</a-button
            >
            <a-button
              icon="a-icon-refresh"
              @click="resetQuery"
              style="margin-left: 10px"
              >重置</a-button
            >
          </a-form-model-item>
          <a-form-model-item style="float: right; margin-right: 0px">
            <a-button
              style=""
              type="primary"
              @click="allClear"
              v-hasPermi="['detect_all_clear']"
              >全部清除</a-button
            >
            <a-button
              style="margin-left: 10px"
              type="primary"
              v-hasPermi="['detect_recognize']"
              :disabled="list.length === 0"
              @click="recognition"
              >识别</a-button
            >
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="upload_warp">
        <div class="upload_warp_img" v-show="list.length != 0">
          <div
            class="upload_warp_img_div"
            v-for="(item, index) of list"
            :key="index"
          >
            <preview-img :src="item.src" />
            <div class="upload_warp_img_div_top">
              <div class="upload_warp_img_div_text" :title="item.imageName">
                {{ item.imageName }}
              </div>
              <a-button
                icon="delete"
                class="upload_warp_img_div_del"
                style="background-color: transparent"
                @click="fileDel(index)"
              ></a-button>
            </div>
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { message } from "ant-design-vue";
import {
  optionFolderNameList,
  optionSampleNumberList,
  optionNameList,
  queryImage,
  queryImageHistory,
  imageRecognition,
  optionModelVersionList,
} from "@/api/image/recognition";
import PreviewImg from "./components/PreviewImg";

export default {
  name: "DetectHome",
  components: {
    PreviewImg,
  },
  data() {
    return {
      // 遮罩层
      loading: false,
      // 显示搜索条件
      showSearch: true,
      // 列表数据
      list: [],
      // 查询参数
      queryParams: {
        sampleNumberArr: [],
      },
      folderList: [],
      sampleNumberList: [],
      modelList: [],
      modelVersionList: [],
    };
  },
  created() {
    this.optionFolderNameList();
    this.optionSampleNumberList();
    this.optionNameList();
    const { sampleNumber, modelName } = this.$route.query;
    if (sampleNumber) {
      if (sampleNumber.indexOf(",") > -1) {
        const sampleNumbers = sampleNumber.split(",");
        this.queryParams = {
          ...this.queryParams,
          sampleNumberArr: sampleNumbers,
          modelName: modelName + "算法",
        };
        this.getModelVersionList();
      } else {
        this.queryParams = {
          ...this.queryParams,
          sampleNumberArr: [sampleNumber],
        };
      }

      this.getList();
    } else {
      this.getListHistory();
    }
  },
  methods: {
    // 获取文件夹名称
    optionFolderNameList() {
      optionFolderNameList().then((res) => {
        if (res.success && res.data) {
          this.folderList = res.data || [];
        }
      });
    },
    // 获取样品编号下拉框数据
    optionSampleNumberList() {
      optionSampleNumberList().then((res) => {
        if (res.success && res.data) {
          this.sampleNumberList = res.data || [];
        }
      });
    },
    // 获取模型数据
    optionNameList() {
      optionNameList().then((res) => {
        if (res.success && res.data) {
          this.modelList = res.data || [];
        }
      });
    },
    // 根据模型获取版本
    changeModelName(e) {
      this.queryParams = {
        ...this.queryParams,
        modelVersion: undefined,
      };
      this.getModelVersionList();
    },
    // 获取模型版本数据
    getModelVersionList() {
      optionModelVersionList({
        name: this.queryParams.modelName,
      }).then((res) => {
        if (res.success && res.data) {
          this.modelVersionList = res.data || [];
          const { modelName } = this.$route.query;
          if (modelName && this.modelVersionList.length > 0) {
            this.queryParams = {
              ...this.queryParams,
              modelVersion: this.modelVersionList[0].modelVersion,
            };
          }
        }
      });
    },
    // 历史记录查询
    getListHistory() {
      queryImageHistory().then((res) => {
        if (res.success && res.data) {
          const sampleNumber = res.data.sampleNumber;
          this.queryParams = {
            ...res.data,
            sampleNumberArr: sampleNumber ? JSON.parse(sampleNumber) : [],
          };
        }
        this.getList();
      });
    },
    /** 查询AI模型管理列表 */
    getList() {
      if (!this.queryParams.sampleNumberArr) {
        message.error("请选择样本编号");
        return;
      }
      this.loading = true;
      queryImage({
        sampleNumberArr: this.queryParams.sampleNumberArr || [],
      }).then((res) => {
        this.list = (res.data || []).map((item) => {
          return {
            ...item,
            src: "data:image/jpeg;base64," + item.orgImageCode,
          };
        });
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.$refs["queryForm"].resetFields();
      this.list = [];
      // this.handleQuery();
    },
    fileDel(index) {
      this.list.splice(index, 1);
    },
    // 全部清除
    allClear() {
      this.list = [];
    },
    // 识别
    recognition() {
      if (!this.queryParams.modelName) {
        message.error("请选择AI模型");
        return;
      }
      if (!this.queryParams.modelVersion) {
        message.error("请选择模型版本");
        return;
      }
      const ids = this.list.map((item) => item.id);
      this.loading = true;
      imageRecognition({
        ids,
        modelName: this.queryParams.modelName,
        modelVersion: this.queryParams.modelVersion,
      }).then((res) => {
        this.loading = false;
        if (res.success) {
          message.success("识别成功，可到“常规操作”查看识别结果");
        }
      });
    },
  },
};
</script>
<style scoped>
.upload_warp_img_div_del {
  position: absolute;
  top: 4px;
  width: 16px;
  right: 4px;
  border: none;
}
.upload_warp_img_div_top {
  position: relative;
  /* bottom: 0; */
  width: 100%;
  height: 40px;
  background-color: #fff;
  line-height: 40px;
  text-align: left;
  /* color: #fff; */
  font-size: 12px;
  text-indent: 4px;
}
.upload_warp_img_div_text {
  white-space: nowrap;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.upload_warp_img_div img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}

.upload_warp_img_div {
  /* position: relative; */
  height: 112px;
  width: 150px;
  /* border: 1px solid #ccc; */
  margin: 0px 5px 5px 0px;
  /* float: left; */
  line-height: 112px;
  display: inline-block;
  text-align: center;
  background-color: #eee;
  cursor: pointer;
  margin-right: 8px;
}

.upload_warp_text {
  text-align: left;
  margin-bottom: 5px;
  padding-top: 5px;
  text-indent: 14px;
  border-top: 1px solid #ccc;
  font-size: 14px;
}

.upload_warp_right {
  float: left;
  width: 57%;
  margin-left: 2%;
  height: 100%;
  border: 1px dashed #999;
  border-radius: 4px;
  line-height: 130px;
  color: #999;
}
.upload_warp_left button {
  margin: 1px 5px 0px 0px;
  cursor: pointer;
}
.upload_warp_left {
  float: left;
}
.upload_warp_left .btn-success {
  background: rgb(45, 140, 240);
  color: #fff;
}
.upload_warp {
  margin: 6px;
  margin-bottom: 0px;
}
.upload_warp_img {
  box-shadow: 0px 1px 15px 0px rgba(61, 74, 148, 0.6);
  border-radius: 4px;
  padding: 10px;
  padding-bottom: 0px;
}
.upload {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  background-color: #fff;
  width: 100%;
  box-shadow: 0px 1px 0px #ccc;
  border-radius: 4px;
}
.ant-select,
.ant-input {
  width: 150px !important;
}
.ant-modal-close {
  top: -30px;
  right: -46px;
  color: white;
}
</style>
